<template>
  <div class="switching">
    <Header />
    <b-container class="switching-container">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="row justify-content-center mx-auto my-auto">
            <div class="text-center">
              <img src="@/assets/img/loading/loading.gif" width="100px" />
            </div>
          </div>
        </template>
        <b-row class="justify-content-md-start back-nav">
          <div class="col-lg-12 col-md-6">
            <b-link
              :to="
                '/switching-product-detail' +
                '/' +
                fund_code_out +
                '/' +
                fund_code_in
              "
              ><b-icon class="mx-2" icon="chevron-left"></b-icon
              ><span>Kembali</span></b-link
            >
          </div>
        </b-row>

        <b-row class="justify-content-md-center p-4">
          <b-card class="swtiching-order p-4">
            <b-form>
              <div class="swtiching-title">
                <b-row class="justify-content-center my-2">
                  <h4>Alihkan Produk</h4>
                </b-row>
              </div>

              <div class="switching-from">
                <b-card class="switching-from-card">
                  <b-row class="mb-2">
                    <b-col class="my-auto">
                      <p class="title">Dari</p>
                      <p class="fund-name">{{ portfolio.product.fund_name }}</p>
                      <h2 class="fund-type">
                        {{ portfolio.product.fund_type }}
                      </h2>
                    </b-col>
                  </b-row>
                </b-card>
              </div>

              <div class="swiching-icon">
                <img
                  src="@/assets/img/icons/transactions/arrow-down-circle.webp"
                />
              </div>

              <b-card class="switching-to-card">
                <b-row>
                  <div class="switching-to">
                    <p class="title ml-3">Ke</p>
                  </div>
                </b-row>

                <div class="fund-name">
                  <b-row class="justify-content-start">
                    <div class="col-3 col-lg-2 text-left mb-2">
                      <div v-if="product.image == null">
                        <img
                          :src="
                            'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                            product.fund_name
                          "
                        />
                      </div>
                      <div v-else>
                        <img
                          :src="`${imageCore}/images/products/${product.image}`"
                          alt="Fund Image"
                        />
                      </div>
                    </div>
                    <div class="col-11 col-lg-9 text-left">
                      <h4>
                        {{ product.fund_name }}
                      </h4>
                      <p>{{ product.fund_type }}</p>
                    </div>
                    <div class="col-1 col-lg-1 text-left">
                      <b-link
                        :href="
                          '/switching-list-product/' +
                          portfolio.product.fund_code +
                          '/' +
                          portfolio.product.investment_manager.id
                        "
                        ><b-icon
                          style="color: #6b7280"
                          icon="chevron-right"
                        ></b-icon
                      ></b-link>
                    </div>
                  </b-row>
                </div>
              </b-card>

              <div class="input-nominal-title">
                <b-row>
                  <div class="col-lg-6 col-md-6 text-left mb-2 my-auto">
                    <h2 class="my-3">Masukkan Nominal</h2>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 text-right mb-2 my-auto"
                    v-if="showSwitchAll"
                  >
                    <b-form-checkbox
                      id="switch-all"
                      name="switch-all"
                      :checked="checkedAllSwitch"
                      @change="checkAll"
                    >
                      Alihkan Semua
                    </b-form-checkbox>
                  </div>
                </b-row>
              </div>

              <div class="input-nominal mt-4">
                <input
                  name="radioSwitch"
                  @change="radioCheck('A')"
                  :checked="radioA"
                  type="radio"
                  class="nominal-radio"
                />
                <b-row class="ml-3 mt-n2 mb-n4">
                  <div class="col-lg-6 col-md-6 my-auto text-left">
                    <div class="by-nominal">
                      <p>Berdasar Nominal</p>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 my-auto text-left text-lg-right"
                  >
                    <div class="limit-nominal">
                      <p>
                        Tersedia {{ portfolio.product.fund_ccy
                        }}{{ formatPrice(amount_from_porto_detail) }}
                      </p>
                    </div>
                  </div>
                </b-row>

                <b-row class="p-4">
                  <b-form-input
                    id="nominl-input"
                    v-model="switch_nominal"
                    @change="input_nominal()"
                    @click="click_nominal()"
                    type="text"
                    :min="0"
                    :max="amount_from_porto_detail"
                    placeholder="IDR"
                    @keypress="isNumber($event)"
                  >
                  </b-form-input>
                </b-row>

                <b-row class="p-4 mt-n4">
                  <div class="limit-nominal">
                    <p>
                      Nominal adalah estimasi berdasarkan harga NAV/unit
                      {{
                        moment(porto_detail.nav_date).format(" DD MMM YYYY")
                      }}.
                    </p>
                  </div>
                </b-row>
                <input
                  name="radioSwitch"
                  @change="radioCheck('B')"
                  type="radio"
                  value="B"
                  class="unit-radio"
                  :checked="radioB"
                />

                <b-row class="ml-3 mt-n2 mb-n4">
                  <div class="col-lg-6 col-md-6 my-auto text-left">
                    <div class="by-unit">
                      <p>Berdasar Unit</p>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 my-auto text-left text-lg-right"
                  >
                    <div class="limit-unit">
                      <p>
                        Tersedia
                        {{ formatNab(unit_from_porto_detail_input) }}
                        unit
                      </p>
                    </div>
                  </div>
                </b-row>

                <b-row class="p-4">
                  <b-form-input
                    id="unit-input"
                    v-model="switch_unit"
                    type="text"
                    :min="0"
                    :max="unit_from_porto_detail_input"
                    @change="input_unit()"
                    @click="click_unit()"
                    placeholder="Unit"
                    @keypress="isNumber($event)"
                  >
                  </b-form-input>
                </b-row>
                <b-row class="p-4 mt-n4"> </b-row>
              </div>
            </b-form>
            <b-button block @click="nextReview()" class="mt-2 btn-tertiary py-2"
              >Lanjut</b-button
            >
          </b-card>
        </b-row>
      </b-skeleton-wrapper>
    </b-container>

    <div>
      <b-modal
        id="modal-failed-register"
        v-model="unitLimit"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="p-4 my-2 text-dark">Jumlah Unit tidak tersedia!</h1>
          <p>Cek kembali jumlah unit Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="nominalLimit"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="p-4 my-2 text-dark">Jumlah nominal tidak tersedia!</h1>
          <p>Cek kembali jumlah nominal Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="unitLimitSell"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="p-4 my-2 text-dark">
            Jumlah Unit tidak mencukupi untuk dijual!
          </h1>
          <p>Cek kembali jumlah unit Anda.</p>
        </div>
      </b-modal>
      <b-modal
        id="modal-failed-register"
        v-model="nominalLimitSwitch"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
      >
        <div class="d-block text-center">
          <img
            src="@/assets/img/ekyc/data-not-correct.svg"
            alt="data-not-correct"
          />
          <h1 class="header-modal p-4 my-2 text-dark">
            Jumlah nominal tidak mencukupi untuk dialihkan!
          </h1>
          <p>Cek kembali jumlah nominal Anda.</p>
        </div>
      </b-modal>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import numeral from "numeral";
import instance from "@/utils/api";

export default {
  name: "Switching",
  metaInfo: {
    title: "Pengalihan | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 2,
      product: {
        fund_name: "",
      },
      fund_code_out: "",
      fund_code_in: "",
      portfolio: {
        product: {
          fund_name: "",
          investment_manager: {
            id: null,
          },
          nav: {
            nav_per_unit: "",
            nav_date: "",
          },
        },
      },
      switch_nominal: 0,
      switch_unit: 0,
      switch_all_units: "",
      showSwitchAll: true,
      porto_detail: {},
      radioA: false,
      radioB: false,
      checkedAllSwitch: false,
      amount_from_porto_detail: 0,
      unit_minimum_switch: 0,
      unit_from_porto_detail_input: 0,
      unitLimit: false,
      nominalLimit: false,
      unitLimitSell: false,
      nominalLimitSwitch: false,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      access_token: "auth/access_token",
      check: "auth/check",
      user: "auth/user",
      app_url: "app_url",
    }),
  },
  created() {
    this.fund_code_in = this.$route.params.fund_code_in;
    this.fund_code_out = this.$route.params.fund_code_out;
    this.fetchFund();
    this.fetchPortfolio();
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatPrice(value) {
      let val = Math.ceil(value).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatPriceInput(value) {
      return Math.ceil(value);
    },
    async fetchPortfolio() {
      this.loading = true;

      if (this.user.institutional == null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Individual&id=${this.user.individual.id}&fund_code=${this.fund_code_out}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio[0];
        this.porto_detail = response.data.data.portoDetail;
        this.amount_from_porto_detail =
          // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
          parseInt(this.portfolio.amount);
        this.minimum_switch =
          this.portfolio.product.min_sell > this.portfolio.product.min_buy
            ? this.portfolio.product.min_sell
            : this.portfolio.product.min_buy;
        this.unit_minimum_switch =
          this.portfolio.product.min_buy / this.porto_detail.nav_per_unit;
        this.amount_from_porto_detail = this.formatPriceInput(
          this.amount_from_porto_detail
        );
        this.unit_from_porto_detail_input = numeral(
          // this.porto_detail.balance_unit
          this.portfolio.outstanding_unit_balance
        ).format("0,0.0000");
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Institutional&id=${this.user.institutional.id}&fund_code=${this.fund_code_out}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio[0];
        this.porto_detail = response.data.data.portoDetail;
        this.amount_from_porto_detail =
          // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
          parseInt(this.portfolio.amount);
        this.minimum_switch =
          this.portfolio.product.min_sell > this.portfolio.product.min_buy
            ? this.portfolio.product.min_sell
            : this.portfolio.product.min_buy;
        this.unit_minimum_switch =
          this.portfolio.product.min_buy / this.porto_detail.nav_per_unit;
        this.amount_from_porto_detail = this.formatPriceInput(
          this.amount_from_porto_detail
        );
        this.unit_from_porto_detail_input = numeral(
          this.porto_detail.balance_unit
        ).format("0,0.0000");
      }
      this.loadingTime = 0;
    },
    async fetchFund() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code_in}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }

      this.product = response.data.data;
      this.loadingTime = 0;
    },

    checkAll() {
      if (event.target.checked) {
        this.radioA = false;
        this.radioB = false;
        this.checkedAllSwitch = true;
        localStorage.switch_all_units = "Y";
        localStorage.is_check = "unit";
        this.switch_nominal = this.amount_from_porto_detail;
        this.switch_unit = this.unit_from_porto_detail_input;
      } else {
        localStorage.switch_all_units = "";
      }
    },

    radioCheck(val) {
      if (val == "A") {
        this.selected = "A";
        this.radioB = false;
        localStorage.is_check = "nominal";
        this.checkedAllSwitch = false;
        localStorage.switch_all_units = "";
      } else if (val == "B") {
        this.selected = "B";
        this.radioA = false;
        localStorage.is_check = "unit";
        this.checkedAllSwitch = false;
        localStorage.switch_all_units = "";
      }
    },

    click_nominal() {
      this.switch_nominal = null;
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
    },

    input_nominal() {
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
      if (
        this.switch_nominal >
        // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit
        this.amount_from_porto_detail
      ) {
        this.nominalLimit = true;
        this.switch_nominal =
          // this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
          this.amount_from_porto_detail;
        this.switch_unit = this.switch_nominal / this.porto_detail.nav_per_unit;
      } else {
        this.switch_unit = this.switch_nominal / this.porto_detail.nav_per_unit;
      }
    },

    click_unit() {
      this.switch_unit = null;
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
    },

    input_unit() {
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
      // if (this.switch_unit > this.porto_detail.balance_unit) {
      if (
        this.switch_unit > parseInt(this.portfolio.outstanding_unit_balance)
      ) {
        this.unitLimit = true;
        this.switch_unit = this.portfolio.outstanding_unit_balance;
        this.switch_nominal = this.switch_unit * this.porto_detail.nav_per_unit;
      } else {
        this.switch_nominal = this.switch_unit * this.porto_detail.nav_per_unit;
      }
    },

    slideNominal() {
      this.radioA = true;
      this.radioB = false;
      localStorage.is_check = "nominal";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
      this.switch_unit = this.switch_nominal / this.porto_detail.nav_per_unit;
      this.switch_unit = numeral(this.switch_unit).format("0,0.0000");
    },

    slideUnit() {
      this.radioA = false;
      this.radioB = true;
      localStorage.is_check = "unit";
      this.checkedAllSwitch = false;
      localStorage.switch_all_units = "";
      this.switch_nominal = this.formatPriceInput(
        this.switch_unit * this.porto_detail.nav_per_unit
      );
    },

    nextReview() {
      if (this.portfolio.product.min_sell == null) {
        if (this.checkedAllSwitch == true) {
          localStorage.switch_nominal = this.switch_nominal;
          localStorage.switch_unit = this.switch_unit;
          this.$router.push(
            "/switching-review/" + this.fund_code_out + "/" + this.fund_code_in
          );
        } else if (this.switch_nominal < 100000) {
          this.nominalLimitSwitch = true;
          return false;
        } else if (this.switch_unit < 100000 / this.porto_detail.nav_per_unit) {
          this.unitLimitSell = true;
          return false;
        } else if (this.switch_unit > this.portfolio.outstanding_unit_balance) {
          this.unitLimitSell = true;
          return false;
        } else {
          localStorage.switch_nominal = this.switch_nominal;
          localStorage.switch_unit = this.switch_unit;
          this.$router.push(
            "/switching-review/" + this.fund_code_out + "/" + this.fund_code_in
          );
        }
      } else {
        if (this.checkedAllSwitch == true) {
          localStorage.switch_nominal = this.switch_nominal;
          localStorage.switch_unit = this.switch_unit;
          this.$router.push(
            "/switching-review/" + this.fund_code_out + "/" + this.fund_code_in
          );
        } else if (this.switch_nominal < this.portfolio.product.min_sell) {
          this.nominalLimitSwitch = true;
          return false;
        } else if (
          this.switch_unit <
          this.portfolio.product.min_sell / this.porto_detail.nav_per_unit
        ) {
          this.unitLimitSell = true;
          return false;
        } else {
          localStorage.switch_nominal = this.switch_nominal;
          localStorage.switch_unit = this.switch_unit;
          this.$router.push(
            "/switching-review/" + this.fund_code_out + "/" + this.fund_code_in
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switching {
  background: #f3f4f6;
}
.switching-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.switching-review {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
    text-decoration: none;
  }
}

.back-nav-to {
  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
    text-decoration: none;
  }
}

.swtiching-order {
  width: 600px;
}

.swtiching-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }
}

.switching-from {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
    margin-bottom: 6px;
  }
  .fund-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
    margin-bottom: 6px;
  }

  .fund-type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
  .unit-value {
    font-style: normal;
    font-weight: bold;
    color: #111827;
  }
}

.switching-from-card {
  background: #f3f4f6;
  border-bottom-left-radius: none !important;
  border-bottom-right-radius: none !important;
}

.switching-to-card {
  margin-top: -28px;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.switching-to {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
    margin-bottom: 6px;
  }
}

.swiching-icon {
  position: relative;
  bottom: 16px;
  left: 26px;
  z-index: 1;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
}

.input-nominal-title {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

hr {
  margin: 0 !important;
}

.by-nominal {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.limit-nominal {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.custom-range::-webkit-slider-thumb {
  background: $primaryColor;
}

.custom-range::-moz-range-thumb {
  background: $primaryColor;
}

.custom-range::-ms-thumb {
  background: $primaryColor;
}

.nominal-radio {
  position: relative;
  top: 18px;
  left: 8px;
}

.unit-radio {
  position: relative;
  top: 18px;
  left: 8px;
}

.by-unit {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.limit-unit {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.custom-control-label {
  font-family: $inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #111827 !important;
}

.form-control:focus {
  background: #f7f7f8;
  outline: none !important;
  border: 1px solid $primaryColor !important;
  box-shadow: none !important;
}
.header-modal {
  font-size: 18px;
}
@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  h2 {
    font-size: 16px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  .by-nominal {
    p {
      font-size: 14px;
    }
  }
  .limit-nominal {
    p {
      font-size: 14px;
    }
  }
  .limit-unit {
    p {
      font-size: 14px;
    }
  }
  .by-unit {
    p {
      font-size: 14px;
    }
  }
  .limit-nominal {
    p {
      font-size: 14px;
    }
  }
}
</style>
